import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import clsx from 'clsx';

import { AuthContext, ThemeContext } from 'shared/providers';

import { api } from 'api';

import {
    CompanyLanguages,
    CustomizationLoginShopModalType,
    CustomizationShopModalsType,
    LoginType,
} from 'shared/types';

import { ModalType, useModal, useNavigate } from 'shared/hooks';

import { AuthHelper, ParseHelper, ValidationHelper } from 'shared/helpers';

import { Button, Icon, Input, InputWrapper, Modal, Typography, InputError, FormError } from 'shared/components/ui';

import styles from './Login.module.scss';

const formInitialValues = {
    email: '',
    password: '',
};

type ModalBaseProps = {
    data?: CustomizationLoginShopModalType;
    isOpen: boolean;
    closeModal: () => void;
    openModal: (modal: ModalType) => void;
};

const ModalBase: React.FC<ModalBaseProps> = ({ data, isOpen, closeModal, openModal }) => {
    const { t } = useTranslation();
    const [loginError, setLoginError] = useState<null | string>(null);
    const [showPassword, changeShowPassword] = useState(false);
    const { changeAuthorization, setSettings } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = async (login: LoginType, helpers: FormikHelpers<LoginType>) => {
        const { status, data, error } = await api.authService.login(login);

        if (status === 200 && data) {
            AuthHelper.setAuthToken(data.auth_token);
            const settings = await api.authService.getAccountSettings();
            changeAuthorization(true);
            setSettings(settings.data);
            helpers.resetForm();
            closeModal();
            if (window.location.pathname.split('/').pop() === 'email-validation') {
                navigate(`/`);
            }
        }

        if (status !== 200) {
            if (error !== null) {
                setLoginError(error.detail[0]);
            } else {
                setLoginError('Email or password invalid');
            }
        }
    };

    useEffect(() => {
        setLoginError(null);
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal.bind(null, LOGIN_MODAL_NAME)} maxWidth={660}>
            <Formik
                initialValues={formInitialValues}
                isInitialValid={false}
                validationSchema={ValidationHelper.schemas.login}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, handleChange, handleBlur, values, isValid, errors, touched }) => (
                    <form className={styles.root} onSubmit={handleSubmit}>
                        <Typography.Title htmlElement="h2">{data?.title || 'Login'}</Typography.Title>
                        {data && data.subtitle && (
                            <Typography.Text className={styles.subtitle} wysiwyg={data.subtitle} />
                        )}
                        {loginError && <FormError>{loginError}</FormError>}
                        <InputWrapper label={t('email-address')}>
                            <Input
                                name="email"
                                type="email"
                                placeholder={t('email-placeholder')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                haveError={!!(errors.email && touched.email)}
                            />
                        </InputWrapper>
                        {errors.email && touched.email && (
                            <InputError>{`${t('email-address')} ${t(errors.email)}`}</InputError>
                        )}
                        <InputWrapper label={t('password')}>
                            <Input
                                name="password"
                                className={styles.password}
                                placeholder={t('password-placeholder')}
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="new-password"
                                value={values.password}
                                haveError={!!(errors.password && touched.password)}
                            />
                            {errors.password && touched.password && (
                                <InputError>{`${t('password')} ${t(errors.password)}`}</InputError>
                            )}
                            <Icon
                                className={clsx(styles.eye, 'password-eye')}
                                name={showPassword ? 'close-eye' : 'eye'}
                                onClick={changeShowPassword.bind(null, !showPassword)}
                            />
                        </InputWrapper>
                        <div className={styles.additional}>
                            {/*<Checkbox id="remember-me" label="Remember me" />*/}
                            <div
                                className={clsx(styles.forgot, 'modal-link')}
                                onClick={openModal.bind(null, '#reset-password')}
                            >
                                {t('forgot-password')}
                            </div>
                        </div>
                        <Button isDisabled={!isValid} size="big" className={styles.button} htmlType="submit">
                            {data?.button || 'Log in'}
                        </Button>
                        <div className={styles['bottom-text']}>
                            <span className={clsx(styles.gray, 'no-account')}>{t('no-account')}</span>
                            <span
                                className={clsx(styles.register, 'modal-link')}
                                onClick={openModal.bind(null, '#registration')}
                            >
                                {t('register-now')}
                            </span>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export const LOGIN_MODAL_NAME = '#login';
export const BID_LOGIN_MODAL_NAME = '#bid-login';

const Root = () => {
    const { i18n } = useTranslation();
    const { isOpen, closeModal, openModal } = useModal(LOGIN_MODAL_NAME);
    const { customization } = useContext(ThemeContext);

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationShopModalsType>(
                customization?.shopModals?.settings,
                'login1.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    return <ModalBase data={content?.login1} isOpen={isOpen} closeModal={closeModal} openModal={openModal} />;
};

const Bid = () => {
    const { i18n } = useTranslation();
    const { isOpen, closeModal, openModal } = useModal(BID_LOGIN_MODAL_NAME);
    const { customization } = useContext(ThemeContext);

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationShopModalsType>(
                customization?.shopModals?.settings,
                'login2.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const data = useMemo(() => {
        if (
            customization &&
            customization.shopModals &&
            customization.shopModals.settings[i18n.language as CompanyLanguages]
        ) {
            const login = content?.login2;

            if (login && !login.subtitle)
                login['subtitle'] =
                    'You must be signed in to give your bid. If you have no account yet, please, register';

            return login;
        }
        // const login = content?.login2;

        return {
            subtitle: 'You must be signed in to give your bid. If you have no account yet, please, register',
            button: '',
            title: '',
        };
    }, [customization, i18n.language, content]);

    return <ModalBase data={data} isOpen={isOpen} closeModal={closeModal} openModal={openModal} />;
};

export const LoginModal = {
    Root,
    Bid,
};
