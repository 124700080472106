import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'shared/components/grid';
import { EstateAuctionItem } from 'shared/components/themes/Estate/_components/EsateAuctionItem/EstateAuctionItem';
import { Typography, NoData } from 'shared/components/ui';
import { AuctionItemType } from 'shared/types';
import styles from './MyBidsSection.module.scss';

type MyBidsSectionType = {
    bids: AuctionItemType[];
};
export const MyBidsSection: React.FC<MyBidsSectionType> = ({ bids }) => {
    const { t } = useTranslation();

    console.log('MY BIDS', bids);

    return (
        <section className={styles.myBids}>
            <Container className={styles.content}>
                <Typography.Title htmlElement="h1" className={styles.title}>
                    {t('my-bids')}
                </Typography.Title>

                {bids.length <= 0 && <NoData icon="hammer-big" title={t('no-bids-title')} text={t('no-bids-text')} />}

                {bids.length > 0 && (
                    <div className={styles.bidsList}>
                        {bids.map(bid => (
                            <EstateAuctionItem data={bid} key={bid.id} showBid />
                        ))}
                    </div>
                )}
            </Container>
        </section>
    );
};
