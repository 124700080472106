import React from 'react';
import { useTranslation } from 'react-i18next';
import { EstateHistoryTable } from 'shared/components/sections';
import { Modal, Typography } from 'shared/components/ui';
import { useDeviceDetector, useModal } from 'shared/hooks';
import { AuctionHistoryItemType } from 'shared/types';
import styles from './BidsHistory.module.scss';
import { MobileHistoryTable } from '../../sections/EstateBiddingHistory/MobileHistoryTable';

export const BIDS_HISTORY_MODAL_NAME = '#bids-history';

type BidsHistoryModalProps = {
    bids: AuctionHistoryItemType[];
};
export const BidsHistoryModal: React.FC<BidsHistoryModalProps> = ({ bids }) => {
    const { isOpen, closeModal } = useModal(BIDS_HISTORY_MODAL_NAME);
    const { t } = useTranslation();
    const { device } = useDeviceDetector();
    const isMobile = device === 'mobile';

    return (
        <Modal
            className={styles.historyModal}
            isOpen={isOpen}
            onClose={closeModal.bind(null, BIDS_HISTORY_MODAL_NAME)}
            maxWidth={1170}
        >
            <Typography.Title htmlElement="h2" className={styles.title}>
                {t('auction-history')}
            </Typography.Title>
            {isMobile ? <MobileHistoryTable data={bids} /> : <EstateHistoryTable bids={bids} />}
        </Modal>
    );
};
