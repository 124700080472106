import { formatPrice } from 'api/helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'shared/components/ui';
import Cookies from 'universal-cookie';
import styles from './BidBox.module.scss';

type BidBoxProps = {
    auctionId: string;
    nextBid: number;
    myBidWinning: boolean;
    highestBid: number;
    onPlaceBid: (bid: number) => void;
    endsIn: string;
};

export const BidBox: React.FC<BidBoxProps> = ({ auctionId, nextBid, endsIn, myBidWinning, highestBid, onPlaceBid }) => {
    const [bid, setBid] = useState<string>('');
    // const input = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const isAuctionFinish = new Date(endsIn).getTime() < Date.now();
    const addSeparator = (val: string) => val.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    const removeSeparators = (val: string) => val.replace(/[^0-9]/g, '');
    const currentBid = +removeSeparators(bid);

    const cookies = new Cookies();
    const showWinningMessageId = cookies.get('show_winning_message_id');
    const showWinningMessage = !!cookies.get('show_winning_message') && showWinningMessageId === auctionId;
    const winningBid = cookies.get('show_winning_message');

    const handleChange = (e: any) => {
        if (!(/^[0-9\b]+$/.test(e.target.value.replaceAll("'", '')) || e.target.value === '')) return;
        setBid(addSeparator(removeSeparators(e.target.value)));
    };

    const placeABid = async () => {
        onPlaceBid(currentBid);
        setBid('');
    };

    // Compose a message that is under the bid box, depending on if current user has a winning bid or not
    const getMessage = () => {
        console.log(winningBid, currentBid);
        if (myBidWinning || showWinningMessage) {
            return `${t('winning-bid').replace('{price}', formatPrice(showWinningMessage ? winningBid : highestBid))}`;
        } else {
            return `${t('minimum-next-bid')} ${formatPrice(nextBid)}`;
        }
    };

    return (
        <>
            <section className={styles.root}>
                <div className={styles.bidBox}>
                    <div className={styles.inputBox}>
                        <span>CHF</span>
                        <input
                            type="text"
                            value={bid}
                            // ref={input}
                            placeholder={t('your-bid-placeholder')}
                            onChange={handleChange}
                            disabled={myBidWinning || showWinningMessage || isAuctionFinish}
                        />
                    </div>
                    <Button
                        onClick={() => placeABid()}
                        isDisabled={(nextBid && currentBid < nextBid) || showWinningMessage || isAuctionFinish || false}
                    >
                        {t('place-bid')}
                    </Button>
                </div>
                {!!nextBid && !isAuctionFinish && (
                    <div className={styles.minimumBid}>
                        <Icon name="info-fill" />
                        {getMessage()}
                    </div>
                )}
                {/* Removed since there is no page for bidding rules
                <div className={styles.rules}>
                    <Icon name="hammer" />
                    <a href="/">{t('learn-bidding-rules')}</a>
                </div> */}
            </section>
        </>
    );
};
