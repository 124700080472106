import { http } from 'api/http';
import { AuthHelper } from 'shared/helpers';

import {
    AuctionBidStatusType,
    AuctionBidType,
    AuctionHeroImage,
    AuctionItemType,
    AuctionResponseType,
    PaginationApiType,
    ResponseDataType,
} from 'shared/types';

const url = process.env.REACT_APP_BASE_API_URL;

const getList = async (
    page: number,
    size: number,
    id?: string,
    random?: boolean,
): Promise<ResponseDataType<AuctionResponseType>> => {
    return http.get<AuctionResponseType>(
        `/customers/auctions/list?page=${page}&size=${size}${id ? `&auctionId=${id}` : ''}${
            random ? `&random=true` : ''
        }`,
    );
};

const getSingle = async (id: string): Promise<ResponseDataType<AuctionItemType>> => {
    return http.get<AuctionItemType>(`/customers/auctions/${id}/`);
};

const getHero = async (): Promise<ResponseDataType<AuctionItemType>> => {
    return http.get<AuctionItemType>(`/storefront/hero-auction/`);
};

const getHeroImages = async (): Promise<ResponseDataType<AuctionHeroImage[]>> => {
    return http.get<AuctionHeroImage[]>(`/storefront/get-images/picture/`);
};

const bidPrice = async (data: AuctionBidType): Promise<ResponseDataType<AuctionBidStatusType>> => {
    return http.post<AuctionBidStatusType>(`/bids/create/`, data);
};

const getCurrentBidPrice = async (id: string): Promise<ResponseDataType<AuctionBidStatusType>> => {
    return await http.get<AuctionBidStatusType>(`/bids/winner-bid/${id}`);
};

const getMissed = async (page: number): Promise<ResponseDataType<PaginationApiType<AuctionItemType>>> => {
    return await http.get<PaginationApiType<AuctionItemType>>(`/customers/auctions/list/?missed=true&page=${page}`);
};

const getBiddingHistory = async (id: string, size = 10): Promise<ResponseDataType<any>> => {
    return await http.get<any>(`/bids/history/${id}/?page_size=${size}`);
};

const uploadFinancialProof = async (data: FormData): Promise<any> => {
    const authToken = AuthHelper.getAuthToken();
    if (!authToken) return;
    const response = await fetch(`${url}/v1/proofs/create/`, {
        method: 'POST',
        body: data,
        headers: {
            Authorization: `Token ${authToken}`,
            Accept: 'application/json',
        },
    });
    return response.json();
};

export const createAuctionService = () => ({
    getList,
    getSingle,
    getHero,
    bidPrice,
    getCurrentBidPrice,
    getMissed,
    getBiddingHistory,
    uploadFinancialProof,
    getHeroImages,
});
